body {
  margin: 0;
  background-color: #FFFFFF;
  color: #525252;
  overflow-x: hidden;
  font-size: 18px;
  line-height: 28.8px;
  font-family: 'Montserrat', sans-serif;
}

:root {
  scroll-behavior: unset;
}

a {
  color: #525252;
  text-decoration: none;
  display: block;
}

a:hover {
  color: #525252;
}

input:focus,
input:active,
.form-control:focus,
.form-control:active,
.btn:focus,
.btn:active,
.navbar-toggler:focus,
.navbar-toggler:active {   
  border-color: none !important;
  box-shadow: none !important;
  outline: none !important;
}

h1 {
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 30px;
}

h1 span {
  display: block;
  font-weight: 400;
  font-size: 33px;
}

h2 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 20px;
}

h3 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 25px;
}

h4 {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 20px;
}

p {
  font-size: 18px;
  line-height: 29px;
  margin-bottom: 20px;
}

.font-16 {
  font-size: 16px;
  line-height: 26px;
}

.font-18 {
  font-size: 18px !important;
  line-height: 29px !important;
}

.font-45 {
  font-size: 45px;
  line-height: 63px;
}

.font-40 {
  font-size: 40px;
  line-height: 56px;
}

.font-25 {
  font-size: 25px;
  line-height: 26px;
}

.font-27 {
  font-size: 27px;
  line-height: 43px;
}

p>strong {
  font-weight: 500;
}

.fw-500 {
  font-weight: 500 !important;
}

.color-blue {
  color: #13D3FD;
  font-size: 40px;
  margin-bottom: 40px;
}

p>.color-blue {
  font-size: 18px;
  display: inline;
}

h1>.color-blue {
  font-size: 35px;
  display: inline;
}

h3>.color-blue {
  font-size: 25px;
  display: inline;
}

p a,
h2>a,
h3>a {
  display: inline;
  color: #13D3FD;
}

p a:hover,
h2>a:hover,
h3>a:hover {
  color: #13D3FD;
}

a.link-dark {
  color: #525252;
  text-decoration: underline;
}

a.link-dark:hover {
  color: #13D3FD;
}

.btn-arrow {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #13D3FD;
  align-items: baseline;
  width: fit-content;
  cursor: pointer;
}

.btn-arrow:hover {
  color: #13D3FD;
  text-decoration: underline;
}

.btn-primary {
  background-color: #13D3FD;
  border: 1px solid #828282;
}

.btn-primary:hover {
  box-shadow: 0px 0px 10px 5px rgba(21, 205, 252, 0.2);
  background-color: #13D3FD;
  border: 1px solid #828282;
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
  background-color: #13D3FD;
}

.popover-wrap {
  position: relative;
}

.popover-wrap .popover-item {
  position: absolute;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  border-radius: 15px;
  max-width: 200px;
  text-align: center;
  padding: 10px;
  overflow-wrap: break-word;
  border-bottom-right-radius: 0px;
  bottom: 111%;
  right: 40%;
  display: none;
}

.popover-wrap .popover-item p {
  margin: 0px;
  font-size: 15px;
  line-height: 24px;
}


.popover-wrap .popover-item small {
  font-size: 13px;
  line-height: 16px;
  display: block;
}

.popover-section .d-md-none p {
  font-weight: 400;
  font-size: 13px;
}

.btn-primary.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  font-weight: bold;
  font-size: 16px;
  min-width: 226px;
  min-height: 54px;
  width: fit-content;
  border-radius: 12px;
}

.popover-wrap:hover .popover-item {
  display: block;
}

.popover-btn-wrap {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin: 40px 0px 0px;
}

.search {
  position: relative;
}

.dataResult {
  position: absolute;
  width: 100%;
  background: #FCFCFC;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 0px 12px 5px rgb(196 196 196 / 30%);
  border-radius: 10px;
  margin-top: 5px;
  overflow: hidden;
}

.dataResult a {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  padding: 8px 15px;
}

.dataResult a:hover {
  background: linear-gradient(92.42deg, #13D3FD 0%, #13D3FD 100%);
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

.search-input {
  border: 1px solid black;
  border-radius: 3px;
}

.search-input input {
  border: none;
}

.search-input button {
  border: none;
}

.btn-arrow img {
  margin-left: 5px;
}

section {
  padding: 40px 0px;
}

.bg-gray {
  background: rgba(240, 240, 240, 0.5);
  margin: 40px 0px;
}

nav {
  margin-bottom: 20px;
}
/*
nav .container {
  width: 1200px;
}*/

.dropdown-menu {
  background: #FCFCFC;
  border: 1px solid #F0F0F0;
  box-shadow: 0px 0px 12px 5px rgba(196, 196, 196, 0.3);
  border-radius: 10px;
  padding: 0px;
  margin: 0px !important;
  overflow: hidden;
  border: none;
}

@media screen and (min-width: 990px) {
  /*.dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-menu.show {
    display: none;
  }
  .dropdown-close .dropdown-menu {
    display: none !important;
  }*/
}

@media screen and (max-width: 991px) {
  #chart-wrap-section .container-fluid {
    max-width: 720px;
  }
}

nav .dropdown-menu li:not(:first-child) {
  border-top: 1px solid #F0F0F0;
}

.navbar-nav .nav-link {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

nav .dropdown-item {
  padding: 8px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  min-width: 239px;
}

nav .dropdown-item:hover {
  background: linear-gradient(92.42deg, #13D3FD 0%, #13D3FD 100%);
  color: #FFFFFF;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
}

nav .dropdown-item:focus {
  background-color: #fff;
}


.nav-link,
.nav-link:hover {
  color: #525252 !important;
}

nav .dropdown-toggle::after {
  background-image: url('./images/dropdown.svg');
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  width: 10px;
  height: 5px;
  vertical-align: 0.1em;
}

nav .navbar-brand img {
  width: 160px;
}

.desktop-text-center,
.desktop-text-center a {
  text-align: center;
  margin: auto;
}

.hero-image img {
  display: block;
  margin-left: auto;
}

.hero-text-fluid {
  margin-top: 20px;
  text-align: center;
}

.hero-text-fluid a {
  margin: auto;
}

.hero-text-fluid .text {
  max-width: 786px;
  margin: auto;
}

.collapse-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: unset !important;
  border: none !important;
  background-color: transparent !important;
}

.collapse-btn.chart-collapse-btn img {
  display: none;
}

.collapse-btn.collapsed img {
  transform: rotate(0deg);
  opacity: 1;
}

.collapse-btn.show-less img {
  transform: rotate(180deg);
}

.cart-collapse-bottom {
  display: none;
}

.chart-wrap {
  display: flex;
  justify-content: space-around;
  margin: 50px 0px;
  margin-bottom: 0px;
}

.chart {
  max-width: 500px;
  width: 32%;
}

.chart * {
  max-height: 300px !important;
}

.card-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 50px 0px;
  gap: 40px;
}

.card-wrap.card-2-col .card {
  width: 48%;
}

.card {
  display: flex;
  flex-direction: column;
  width: 31%;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 12px rgb(196 196 196 / 30%);
  border-radius: 10px;
  background-color: #FFFFFF;
  position: relative;
  padding: 31px;
}


.card p {
  font-size: 15px;
  margin-bottom: 0px;
  line-height: 24px;
}
.card a {
  margin-top: auto;
  font-size: 18px;
}

.card h3,
.card h4 {
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
}

.card-with-image-wrap {
  display: flex;
  justify-content: space-between;
  margin: 25px 0px 36px 0px;
}

.card-with-image-wrap-margin {
  margin-top: 0px;
  margin-bottom: 0px;
}

.card-with-image-wrap-section {
  padding-top: 70px;
  padding-bottom: 70px;
}

.card-with-image-wrap.space-around {
  justify-content: space-around;
}

.card-with-image-wrap.space-around .card-with-image {
  width: 33%;
}

.card-with-image-wrap.four-rows .card-with-image {
  width: 23%;
}

.card-with-image-wrap.four-rows .card-top {
  overflow: hidden;
}

.card-with-image-wrap.four-rows .card-body {
  padding: 30px 33px;
}

.card-with-image-wrap.four-rows .card-top img {
  margin-top: 0px;
}

.card-with-image {
  box-shadow: 0px 0px 15px 12px rgba(196, 196, 196, 0.3);
  border-radius: 10px;
  border: none;
  background: linear-gradient(180.14deg, #C9F7F0 0.12%, #C6F5F9 69.5%);
  width: 32%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.card-with-image:hover {
  background: linear-gradient(180.14deg, #C9F7F0 0.12%, #C6F5F9 69.5%);
  box-shadow: 0px 0px 15px 12px rgba(196, 196, 196, 0.7);
}

.card-with-image:hover .btn-arrow {
  text-decoration: underline;
}

.card-with-image:hover .desc {
  color: #525252;
}


.card-top {
  /*height: 232px;*/
  position: relative;
  display: flex;
}

.card-top img {
  /* position: absolute; */
  bottom: 0px;
  left: 0px;
  /* height: 300px; */
  margin-top: -6%;
}

/*
.card-top.InstallSafexCryptoWallet img {
  bottom: -10px;
}

.card-top.BuyProductsonSafexMarket img {
  width: 86%;
  left: 50%;
  transform: translate(-50%, 0px);
}

.card-top.DevelopingonSafex img {
  width: 85%;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: -3px;
}

.SellProductsonSafexMarket .card-top img {
  width: 99%;
  left: 50% !important;
  transform: translate(-50%, 0px) !important;
  bottom: 0px;
}

.SellitemsonSafexMarketplace .card-top img {
  width: 99%;
  left: 50%;
  transform: translate(-50%, 0px);
}

.RunSafexNode .card-top img {
  height: 100%;
  width: auto;
  left: 50%;
  transform: translate(-50%, 0px);
}

.SafexCommunity .card-top {
  position: relative;
  overflow: hidden;
}

.SafexCommunity .card-top img {
  height: 100%;
  width: auto;
  left: 50%;
  transform: translate(-50%, 0px);
  max-width: none;
}

.ContributetoSafexEcosystem .card-top {
  position: relative;
  overflow: hidden;
}

.ContributetoSafexEcosystem .card-top img {
  height: 100%;
  width: auto;
  left: 50%;
  transform: translate(-50%, 0px);
  max-width: none;
}

.card-top.MiningSafexCashtoSafexTWMWallet {
  z-index: 100;
}

.card-top.MiningSafexCashtoSafexTWMWallet img {
  width: 99%;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: -17px;
}

.card-top .DevelopingonSafex img {
  bottom: -3px;
}


.HowSafeEcosystemWorks .card-top {
  position: relative;
  overflow: hidden;
}*/
/*
.HowSafeEcosystemWorks .card-top img {
  height: 100%;
  width: auto;
  position: absolute;
  max-width: unset;
  right: 0px;
  left: unset;
}

.PurchaseSafexCryptoCoins .card-top img {
  top: 50%;
  transform: translate(0%, -50%);
}*/


.card-body {
  background-color: #FFFFFF;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 30px 40px;
}

.card-body .btn-arrow p {
  font-size: 23px;
  margin-bottom: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.card-body .btn-arrow p span {
  white-space: nowrap;
}

.card-body p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 0px;
}


.card-fluid {
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 12px rgba(196, 196, 196, 0.2);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  min-height: 300px;
}

.card-fluid.green-linear {
  background: linear-gradient(92.35deg, rgba(255, 255, 255, 0.4) 67.16%, rgba(84, 234, 184, 0.4) 100%);
}

.card-fluid.blue-linear {
  background: linear-gradient(272.97deg, rgba(19, 211, 253, 0.3) 0.4%, rgba(255, 255, 255, 0.3) 34.71%);
}

.card-fluid.gray-linear {
  background: radial-gradient(103.27% 1631.02% at 100% 100%, rgba(196, 196, 196, 0.8) 0%, rgba(255, 255, 255, 0) 38.89%);
}

.card-fluid-body {
  width: 70%;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.card-fluid-body h2 {
  margin-bottom: 0px;
  font-weight: normal;
}

.card-fluid-body .card-fluid-main-text {
  font-weight: normal;
  font-size: 16px;
  margin: auto;
  line-height: 25.6px;
  margin-left: 0px;
}

.card-fluid-body .card-fluid-subtitle {
  font-weight: normal;
  font-size: 16px;
}

.card-fluid-body h3 {
  font-weight: 400;
  font-size: 23px;
}

.card-fluid-body .btn-arrow {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-top: auto;
  margin-top: 20px;
}

.MiningSafexCashCryptocurrency .card-fluid-body {
  width: 61%;
}

.MiningSafexCashCryptocurrency .card-fluid-image {
  width: 51%;
  position: relative;
}

.MiningSafexCashCryptocurrency .card-fluid-image img {
  width: 100%;
  margin-top: -68px;
  margin-bottom: -63px;
}

.BuySafexCashSFXonCryptoExchanges .card-fluid-image {
  position: relative;
}

.BuySafexCashSFXonCryptoExchanges .card-fluid-image img {
  height: 100%;
  position: absolute;
  width: auto;
  max-width: unset;
  right: 0px;
}

.BuySafexCashSFXonCryptoExchanges .card-fluid-body {
  width: 63%;
}

.BuySafexTokensSFTonXcalibraCryptoCurrencyExchange .card-fluid-image {
  position: relative;
}

.BuySafexTokensSFTonXcalibraCryptoCurrencyExchange .card-fluid-image img {
  height: 100%;
  position: absolute;
  width: auto;
  max-width: unset;
  right: 10px;
}

.BuySafexTokensSFTonXcalibraCryptoCurrencyExchange .card-fluid-body {
  width: 63%;
}


.BuyWrappedSafexCashWSFXonPancakeSwap .card-fluid-image {
  position: relative;
}

.BuyWrappedSafexCashWSFXonPancakeSwap .card-fluid-image img {
  height: 100%;
  position: absolute;
  width: auto;
  max-width: unset;
  right: 0px;
}

.BuyWrappedSafexCashWSFXonPancakeSwap .card-fluid-body {
  width: 63%;
}

.ExploretheSafexMarketWalletApp .card-fluid-image img {
  margin-top: auto;
}

.collapse-card {
  display: flex;
  flex-direction: column;
}

.collapse-card .card-fluid-body,
.collapse-card .card-fluid-image {
  padding: 0px;
}

.collapse-card .card-fluid-body {
  justify-content: space-between;
  gap: 10px;
}

.collapse-card .card-fluid-body h2 {
  margin-bottom: 10px;
}

.card-fluid.collapse-card {
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px 15px rgb(196 196 196 / 30%);
  border-radius: 10px;
  padding: 0px;
}

.collapse-card .card-fluid-body {
  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 40px;
}

.collapse-card .collapse-card-inner {
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.collapse-card .HowSafexBlockchainWorks .card-fluid-image {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 30px;
}

.collapse-card .SafexCashPaymentMethod .card-fluid-body {
  width: 69%;
}

.collapse-card .SafexCashPaymentMethod .card-fluid-image {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
  width: 31%;
}

.collapse-card .SafexTokenExplained .card-fluid-body {
  width: 63%;
}

.collapse-card .SafexTokenExplained .card-fluid-image {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
  width: 38%;
}

.collapse-card .SafexTWMWalletappExplained .card-fluid-body {
  width: 61%;
}

.collapse-card .SafexTWMWalletappExplained .card-fluid-image {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
  width: 39%;
}

.collapse-card .SafexeCommerceMarketplacePlatform .card-fluid-body {
  width: 63%;
}

.collapse-card .SafexeCommerceMarketplacePlatform .card-fluid-image {
  position: relative;
  overflow: hidden;
  padding-right: 10px;
  width: 38%;
}

.collapse-card .accordion-top {
  display: flex;
}

.card-fluid.collapse-card .collapse-btn {
  display: block;
  height: 46px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.collapse-btn .btn-arrow {
  display: none;
}

.collapse-btn .btn-arrow.collapsed {
  display: inline;
}

.collapse-border {
  margin-top: 0px;
}

.collapse-text {
  width: 95%;
  margin-top: 30px;
}

.collapse-text h2 {
  font-family: 'Montserrat';
}

.collapse-text ul {
  margin-bottom: 27px;
}

.collapse-text p {
  margin-bottom: 27px;
}

.collapse-text .collapse-flex {
  display: flex;
  justify-content: space-between;
}

.collapse-text .collapse-flex>div {
  width: 47%;
}

.collapse-text .col-small {
  width: 70%;
}

.collapse-chart {
  width: 95%;
  display: flex;
  margin-top: 37px;
  margin-bottom: 10px;
}

#card1 .collapse-chart>div {
  display: flex;
}

#card1 .collapse-chart>div img {
  margin-top: auto;
}

.card-fluid.collapse-card .show-less {
  font-size: 15px;
  color: #525252;
  display: flex;
  gap: 8px;
  margin-bottom: -55px;
  margin-right: -19px;
  cursor: pointer !important;
}

.card-fluid.collapse-card .show-less span {
  height: 20px;
}

.card-fluid.collapse-card .show-less img {
  transform: rotate(0deg);
}

.card-fluid-image {
  width: 30%;
  padding-right: 40px;
  display: flex;
  align-items: center;
}

.card-fluid-image img {
  margin-left: auto;
  display: block;
}


/*2*/
.card-fluid2 {
  box-shadow: 0px 0px 15px 12px rgba(196, 196, 196, 0.3);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  min-height: 300px;
}

.card-fluid2:hover .btn-arrow {
  text-decoration: underline;
}

.card-fluid2-body {
  width: 55%;
  display: flex;
  flex-direction: column;
  padding: 40px;
}

.card-fluid2-body .card-fluid2-main-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 25.6px;
  margin-left: 0px;
  margin-top: 20px;
}

.card-fluid2-body .card-fluid2-subtitle {
  font-weight: normal;
  font-size: 16px;
}

.card-fluid2-body .btn-arrow {
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  font-size: 23px;
  margin: 0px;
  display: flex;
  align-items: center;
}

.card-fluid2-image {
  width: 45%;
  padding-right: 40px;
  background: linear-gradient(180deg, #CBF8E9 0%, #C6F5F9 100%);
  position: relative;
  min-height: 300px;
  overflow: hidden;
}

.card-fluid2-image img {
  position: absolute;
  top: 50%;
  display: block;
  transform: translate(0px, -50%);
}



.video {
  width: 60%;
  height: 436px;
  margin: auto;
  margin-top: 30px;
}





.text-section h4 {
  margin-bottom: 30px;
}

.text-section h2 {
  margin-bottom: 30px;
}

.text-section p {
  font-size: 15px;
  line-height: 24px;
}

.text-section ul {
  padding-left: 20px;
  font-size: 15px;
  line-height: 25.6px;
}

.text-section a {
  font-size: 16px;
}

.text-section .text-mx-w {
  max-width: 687px;
}

.text-section .subtitle {
  font-size: 16px;
  margin-bottom: 25px;
}

.text-card {
  background: #FFFFFF;
  border: 1px solid #828282;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 12px rgb(196 196 196 / 30%);
  border-radius: 10px;
  padding: 20px;
  max-width: 589px;
}

.text-card h4 {
  margin-bottom: 20px;
}

.price-card {
  border: 1px solid #C4C4C4;
  border-radius: 10px;
  width: fit-content;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 13px 1px;
  width: 314px;
}

.price-card.green {
  background: linear-gradient(180deg, rgba(203, 248, 233, 0.3) 0%, rgba(84, 234, 184, 0.3) 100%);
}

.price-card.blue {
  background: linear-gradient(180deg, rgba(233, 250, 255, 0.3) 0%, rgba(19, 211, 253, 0.3) 100%);
}

.price-card.gray {
  background: linear-gradient(180deg, rgba(233, 250, 255, 0.3) 0%, rgba(130, 130, 130, 0.3) 100%);
}

.price-card p {
  margin: 0px !important;
}

.price-card .price-text {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
}

.price-card .tablet-text {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  display: none;
}

.price-card .current-price {
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.price-card .price-action {
  display: flex;
  font-weight: normal;
  justify-content: space-evenly;
}

.price-card .price-action p {
  font-size: 15px;
}

.price-card .price-action img {
  width: 10px;
}

.price-card .percentage {
  display: flex;
  gap: 5px;
}

.coins-card-wrap {
  display: flex;
  justify-content: center;
  gap: 5%;
}


.step {
  background: rgba(240, 240, 240, 0.5);
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 40px;
}

.step .step-number {
  width: fit-content;
  background: rgba(196, 196, 196, 0.5);
  padding: 20px 55px;
  text-align: center;
}

.step .step-number p {
  font-weight: 500;
  font-size: 30px;
  font-family: 'Ubuntu', sans-serif;
}

.step .step-number p span {
  display: block;
}

.step p,
.step h2 {
  margin: 0px;
}

.step .left,
.step .right {
  flex: 1;
}

.step-body {
  text-align: center;
}

.step-body a {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.step-body .btn-arrow {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.step-body h2 {
  margin-bottom: 30px;
  margin-top: 30px;
}

.level-section .level-body ul:first-child {
  margin-bottom: 0px;
}

.level-section .level-body h2:first-child {
  margin-top: 0px;
}

.step-body .col-md-4:first-child h4 {
  margin-top: 0px !important;
}

.step-body h2:first-child {
  margin-top: 0px !important;
}


.step-body ol,
.step-body ul {
  width: fit-content;
  margin: 0px auto 30px auto;
  text-align: left;
  max-width: 1100px;
}

.step-body ol:last-child,
.step-body ul:last-child {
  margin-bottom: 0px;
}

.step-body .video {
  margin-top: 40px;
}

.step-body .step-image {
  margin: 30px 0px;
}

.step-body .step-end {
  text-align: left;
}

.step-body .step-end a {
  margin-left: 0px;
  margin-right: 0px;
}

.step-body .step-end .step-end-text-left {
  max-width: 586px;
}

.step-body .step-body-row {
  display: flex;
  justify-content: space-around;
  text-align: left;
}

.step-body .step-body-row .col-md-4 {
  width: fit-content;
  max-width: 375px;
}

.step-body .step-body-row .col-md-6 {
  width: fit-content;
  max-width: 650px;
}

.step-body .step-body-row ol,
.step-body .step-body-row ul,
.step-body .step-body-row a {
  margin-left: 0px;
  margin-right: 0px;
}

.option-section .step-body .step-body-row {
  justify-content: space-between;
}

.option-section .step-body-right {
  width: 31%;
}

.option-section .step-body .step-body-row .col-md-4 {
  width: 31%;
}


@media (min-width: 1400px) {
  /*.page-content .container {
      max-width: 1400px;
  }*/

  .page-content .container-smaller {
    max-width: 1200px;
  }

  .page-content .container-small {
    max-width: 1285px;
  }
}

.bg-gray-text {
  text-align: center;
}

.bg-gray-text h2 {
  margin-bottom: 0px;
}

.bg-gray-text a {
  margin: auto;
}

.bg-image-fluid {
  overflow: hidden;
}

.bg-image-fluid img{
  width: 100%;
  max-width: unset;
}

.hero-2-cols {
  padding: 0px;
}

.hero-2-cols .hero-text {
  padding-top: 50px;
  max-width: 590px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.hero-2-cols .full-hero-text {
  max-width: 100%;
}

.hero-2-cols .hero-text h1 {
  font-size: 40px;
  margin-bottom: 40px;
}

.hero-2-cols .hero-text p {
  margin-bottom: 20px;
}

.hero-2-cols .hero-text a {
  margin-top: 30px;
  margin-bottom: 20px;
}

.hero-2-cols .hero-text .small-text {
  font-size: 13px;
  line-height: 20.8px;
}

.safex-info-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.safex-info-wrap .source {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.safex-info-wrap .source p {
  font-size: 14px;
  margin: 0px;
}

.safex-info-wrap .center {
  display: flex;
  gap: 80px;
  justify-content: center;
}

.safex-info {
  text-align: center;
}

.safex-info img {
  height: 186px;
}


.safex-info p {
  margin-top: 27px;  
  font-size: 20px;
  line-height: 28px;
}

.keep-in-touch {
  display: flex;
  margin: auto;
  margin-top: 50px;
  max-width: 823px;
}

.keep-in-touch .social-media-icon {
  text-align: center;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.keep-in-touch .social-media-icon div {
  background: #828282;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.keep-in-touch .social-media-icon p {
  margin: 0px;
  margin-top: 13px;
  font-family: 'Ubuntu', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}


.form-wrap {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px 12px rgb(196 196 196 / 30%);
  border-radius: 20px;
  padding: 50px 20px;
}

.form-wrap form {
  width: 60%;
  margin: auto;
  max-width: 500px;
}

.form-group {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-group label {
  font-size: 14px;
}

.form-group .form-control {
  border: 1px solid #828282;
  border-radius: 10px;
}

.form-wrap .btn-primary.btn {
  background: #13D3FD;
  border: 1px solid #828282;
  box-sizing: border-box;
  box-shadow: 0px 0px 12px 5px rgb(21 205 252 / 25%);
  border-radius: 12px;
  margin-top: 35px !important;
}

ul.road-map {
  position: relative;
  padding-left: 50px;
  margin-top: 40px;
}

.road-map::before {
  content: '';
  display: inline-block;
  border-style: dashed;
  border-left: 3px dashed black;
  border-right: 0px;
  height: 95%;
  content: "";
  position: absolute;
  top: 5px;
  left: 13px;
}

ul.road-map li  {
  list-style: none;
  display: flex;
  align-items: center;
  position: relative;
  gap: 15px;
  margin: 15px 0px;
}

ul.road-map li::before {
  content: '';
  display: inline-block;
  height: 25.04px;
  width: 26.91px;
  background-image: url('./images/list.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: -48px;
  transform: translate(0px, -50%);
  top: 50%;
}

ul.road-map li.check::after {
  content: '';
  display: inline-block;
  width: 25.57px;
  height: 19.8px;
  background-image: url('./images/check.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: -42px;
  transform: translate(0px, -74%);
  top: 50%;
}

.trademark-images {
  display: flex;
  justify-content: center;
  margin: 40px 0px;
  gap: 5%;
}

.trademark-images img {
  height: 160px;
  width: auto;
  max-width: 50%;
}

.QR-code-wrap {
  max-width: 278px;
  overflow-wrap: break-word;
}

.coin-logo-wrap img.img-fluid {
  max-height: 210px;
}

ul.list-blue {
  list-style: none;
}

ul.list-blue li {
  display: flex;
}

ul.list-blue li::before {
  content: "\2022";
  color: #13D3FD;
  font-weight: bold;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}

.scroll-to-top {
  position: relative;
}

.scroll-to-top>div {
  position: fixed;
  right: 25px;
  bottom: 30px;
  cursor: pointer;
}

footer {
  margin-top: 20px;
}

footer .social-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
  margin-bottom: 30px;
}

footer .social-media a {
  padding: 10px;
}

footer .social-media img {
  width: 33px;
}

footer .footer-links-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

footer .footer-links h1 {
  font-size: 16px;
  margin-bottom: 10px;
}

footer .footer-links a {
  font-size: 16px;
  padding: 5px 0px;
}

footer .footer-links a:hover {
  text-decoration: underline;
  color: #13D3FD;
}

footer .footer-bottom {
  text-align: center;
  margin-top: 30px;
}

footer .footer-bottom p {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
}

footer .footer-bottom img {
  max-width: 156px;
  margin-bottom: 10px;
}

footer .footer-bottom .footer-mobile-links {
  display: none;
}

footer .footer-bottom .footer-mobile-links a {
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
}

footer .social-media-mobile {
  justify-content: center;
  gap: 10px;
  display: none;
}

footer .social-media-mobile a {
  background-color: #828282;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.first-step-section {
  padding-bottom: 10px;
}

.safex-elements {
  padding-top: 70px;
  padding-bottom: 70px;
  margin-bottom: 0px;
}

.safex-elements .card-wrap {
  margin-top: 70px;
  margin-bottom: 0px;
}

.bg-image-fluid-section {
  padding-bottom: 0px;
  padding-top: 0px;
}

.card-wrap-section {
  padding-top: 70px;
  padding-bottom: 70px;
}

.card-wrap-section .card-wrap {
  margin-top: 0px;
  margin-bottom: 0px;
}

.what-is-safex-bg-gray {
  margin-top: 0px;
}

.card-wrap.card-2-col p {
  font-weight: 400;
  font-size: 18px;
  line-height: 29px;
}


.card-wrap.card-2-col h3 {
  font-weight: 500;
  font-size: 25px;
  line-height: 160%;
  color: #525252;
}

.wallet-card-fluid {
  padding-top: 0px;
}

.wallet-card-with-image-wrap {
  padding-bottom: 0px;
}

.wallet-advice {
  padding: 60px 0px;
  margin-bottom: 0px;
}

.wallet-advice .text-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wallet-advice .text-wrap a {
  margin-top: auto;
  font-weight: 400;
  font-size: 18px;
}

.wallet-advice ul {
  margin-bottom: 50px;
}

.wallet-card-fluid2 {
  padding-top: 30px;
  padding-bottom: 30px;
}


.buy-safex-cash-section .hero-text a {
  margin-top: 30px;
  margin-bottom: 50px;
}

.buy-safex-cash-section .price-card {
  margin-top: 0px;
}

.buy-safex-cash-section .hero-image {
  margin-top: 87px;
  margin-right: -48px;
}

.step-section {
  padding-top: 0px;
  padding-bottom: 40px;
}

.step-section-first {
  padding-top: 70px;
}

.shopping-step-section {
  padding-top: 40px;
}

.mining-step-section {
  padding-top: 0px;
}

.level-section {
  padding-top: 0px;
  padding-bottom: 40px;
}

.level-section .level-body {
  /*max-width: 1100px;
  margin: auto;*/
}

.level-section .level-body a {
  margin-top: 60px;
}

.level-section .step .left,
.level-section .step .right {
  flex: none;
}

.level-section .step .center {
  text-align: left;
  margin-left: 40px;
}

.choose-exchange-section p {
  font-weight: 400;
  font-size: 16px;
}

.choose-exchange-section a {
  font-weight: 400;
}


.home-gray-section h2 {
  font-family: 'Montserrat', sans-serif;
}


.hero-2-cols .order-md-1 {
  order: 2!important;
}

.hero-2-cols .order-md-2 {
  order: 1!important;
}

.hero-2-cols .row>div {
  flex: unset;
  width: 100%;
}

.hero-2-cols .hero-image img {
  width: 425px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.reliable-section {
  margin-top: 0px;
  text-align: center;
}

.reliable-section a {
  margin-top: 14px;
  margin: auto;
}

.how-card-section {
  padding-top: 0px;
  padding-bottom: 0px;
}

.how-card-section .card-wrap {
  margin-top: 10px;
}

.safex-info-wrap-section {
  padding-bottom: 0px;
}


.card-with-image-wrap .card-body {
  padding: 30px 36px;
}

.eco-card-with-image-section .card-with-image-wrap {
  flex-wrap: wrap;
  margin-bottom: 0px;
}

.eco-card-with-image-section .card-with-image-wrap .card-with-image {
  margin-bottom: 80px;
}

.eco-card-with-image-section .card-with-image-wrap .card-with-image .card-top {
 /* min-height: 225px;*/
}

.eco-card-with-image-section .card-body .btn-arrow p {
  font-size: 21px;
}

.eco-card-with-image-section .card-with-image-wrap .card-body {
  padding: 30px 33px;
}

.eco-card-with-image-section {
  padding-bottom: 0px;
}

.hero-align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.have-questions-section h2 {
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 30px;
  line-height: 48px;
  text-align: center;
}

.community-form-section .community-form-section-image {
  display: flex;
  height: 100%;
}

.community-form-section img {
  width: 80%;
  margin-top: auto;
  margin-bottom: auto;
}

.community-link-right {
  padding-left: 60px;
}

.decide-section .row {
  justify-content: space-between;
}

.decide-section .decide-section-left {
  flex: 0 0 auto;
  width: 48%;
  max-width: 575px;
}

.decide-section .decide-section-right {
  flex: 0 0 auto;
  width: 48%;
}

.knowledge-base-section h2 {
  font-size: 24px;
}

.privacy-necessary-section h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 40px;
}

.safex-coin-box-wrap {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.safex-coin-box-wrap .safex-coin-box-link-center {
  margin: auto;
  width: fit-content;
}

.visual-content-section h2 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  text-align: center;
}

.how-to-mine-cash-section {
  padding-bottom: 0px;
}

.how-to-mine-cash-section h1 {
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 35px;
  margin-bottom: 13px;
}

.how-to-mine-cash-section p {
  margin-bottom: 2px;
}

.mining-pool-chart-section .chartCollapse2 {
  margin: auto;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration-line: underline;
  color: #525252;
}

.mining-pool-chart-section .chart-wrap {
  gap: 0px;
}

.mining-pool-chart-section .chartCollapse2 .arrow-up {
  display: inline;
}

.mining-pool-chart-section .chartCollapse2 .arrow-down {
  display: none;
}

.mining-pool-chart-section .chartCollapse2.collapsed {
  color: #13D3FD;
}

.mining-pool-chart-section .chartCollapse2.collapsed .arrow-up {
  display: none;
}

.mining-pool-chart-section .chartCollapse2.collapsed .arrow-down {
  display: inline;
}

.d-md-none .chart {
  margin-bottom: 0px;
}

.vision-section .row {
  justify-content: space-between;
}

.vision-section .vision-col {
  width: 48%;
}

.vision-section .roadmap-col {
  width: 49%;
}

.share-content-section .form-wrap {
  height: 100%;
  display: flex;
}

.share-content-section .row {
  justify-content: space-between;
}

.share-content-section .share-content-text-col {
  width: 46%;
}

.share-content-section .share-content-form-col {
  width: 50%;
}

.qr-code-section {
  margin-top: 20px;
}

.qr-code-section .col-md-4 {
  display: flex;
  flex-direction: column;
}

.qr-code-section .col-md-4 h4 {
  min-height: 72px;
}

.instructions-mobile-block {
  display: none;
}

.decide-section {
  padding-bottom: 0px;
}

.mining-question-section {
  margin-top: 0px;
}

.coding-on-safex-links {
  margin-top: 10px;
  margin-bottom: 30px;
}

.coins-card-wrap-section {
  margin-bottom: 0px;
}

.links-to-existing-section h1 {
  font-family: 'Montserrat';
}

.tablet-level-title {
  display: none;
}

.vision-section {
  padding-bottom: 0px;
}

.opportunity-section {
  padding-top: 0px;
}

.what-to-know-wallet {
  padding-top: 50px;
  padding-bottom: 30px;
}

.staking-chart {
  max-height: 253px;
}

.staking-video-section .video {
  width: 100%;
  height: 356px;
  margin-top: 20px;
}

.claiming-section a {
  margin-top: 35px;
}

.what-is-staking-section {
  margin-top: 60px;
  margin-bottom: 30px;
}

.what-you-need-staking {
  padding-bottom: 20px;
}

.what-you-need-staking-steps-section {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .knowledge-base-section .row {
    justify-content: space-between;
  }
  .knowledge-base-section .col-md-6 {
      flex: 0 0 auto;
      width: 45%;
  }
}

@media (min-width: 868px) {
  .hero-2-cols .row>div {
    flex: 0 0 auto;
    width: 50%;
  }
  .hero-2-cols .row .col-md-5 {
    flex: 0 0 auto;
    width: 47%;
  }
  .hero-2-cols .row .col-md-7 {
    flex: 0 0 auto;
    width: 53%;
  }
  .hero-2-cols .order-md-1 {
    order: 1!important;
  }
  .hero-2-cols .order-md-2 {
    order: 2!important;
  }
  .hero-2-cols .hero-image img {
    max-width: 100%;
    width: unset;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 30px;
  }
}

@media (max-width: 868px) {
  .what-you-need-staking {
    padding-top: 0px;
  }
  .what-is-staking-section {
    padding: 0px;
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
  .staking-hero {
    padding-bottom: 10px !important;
  }
  .staking-video-section {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .claiming-section {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .card-with-image-section {
    padding-top: 10px;
    padding-bottom: 0px;
  }
  .card-with-image-section .card-with-image-wrap {
    margin-bottom: 25px;
  }
  .promote-safex-hero {
    padding-bottom: 24px !important;
  }
  .share-content-section {
    padding-top: 0px;
  }
  .opportunity-section .card-on-mobile {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 12px rgba(196, 196, 196, 0.3);
    border-radius: 10px;
    padding: 30px 20px;
  }
  .opportunity-section .card-on-mobile p:last-child {
    margin-bottom: 0px;
  }
  .community-link-right {
    padding-left: 0px;
  }
  .hero-align-center {
    margin-top: 15px;
    margin-bottom: 50px;
    justify-content: flex-start;
  }
  .hero-align-center h1 {
    font-weight: 500;
    font-size: 35px;
    line-height: 49px;
  }

  .hero-align-center h1 span {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
  }
  .hero-align-center h1.font-23 {
    font-weight: 500;
    font-size: 23px;
    line-height: 32px;
  }
  .step-section-first {
    padding-top: 0px;
  }
  .step-body .video {
    margin-top: 0px;
  }
  .step-body .btn {
    margin-bottom: 30px;
  }
  .step {
    margin-bottom: 30px;
  }
  .step-section {
    padding-top: 0px;
    padding-bottom: 30px;
  }
  .community-form-section .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
  }

  .community-form-section img {
    max-width: 72%;
    margin: auto;
    display: block;
    margin-bottom: 40px;
  }
  .collapse-card .accordion-top {
    flex-direction: column;
  }
  .card-fluid.collapse-card .show-less span {
    display: none;
  }
  .collapse-text {
    width: 100%;
  }
  .collapse-chart {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .collapse-text .collapse-flex {
    flex-direction: column;
  }
  .collapse-text .collapse-flex>div {
    width: 100%;
  }
  .collapse-text .col-small {
    width: 100%;
  }
  .collapse-border {
    display: none;
  }
  .card-fluid.collapse-card .collapse-btn {
    height: 29px;
    margin-bottom: 15px;
    margin-right: 0px !important;
  }
  .card-fluid-body h1 {
    font-weight: 400;
    font-size: 23px;
  }
  .card-fluid-body {
    font-weight: 400;
    font-size: 16px;
    padding-left: 6px;
  }
  .collapse-text h2 {

  }
  .collapse-text p,
  .collapse-text ul,
  .collapse-text a {
    font-weight: 400;
    font-size: 16px;
  }
  .card-fluid.collapse-card {
    padding: 25px;
  }
  .collapse-card .card-fluid-body {
    padding: 0px !important;
  }
  .collapse-card .SafexTWMWalletappExplained .card-fluid-image {
    width: 100%;
  }
  .collapse-card .collapse-card-inner {
    padding: 0px;
  }
  .collapse .btn-arrow {
    font-weight: 600;
    font-size: 18px;
  }
  .hero-2-cols .hero-text {
    max-width: 100%;
  }
  .level-section .step .center {
    margin-left: 2%;
  }
  .col-md-9 {
    width: 100%;
  }
  .col-md-7 {
    width: 100%;
  }
  .col-md-10 {
    width: 100%;
  }
  .col-md-6 {
    width: 100%;
  }
  .links-to-existing-section {
    margin-top: 0px;
    background-color: white;
  }
  .what-need-section {
    padding-top: 0px;
  }
  .what-is-node-section {
    padding-top: 0px;
  }
  .decide-section {
    padding-top: 10px;
  }
  .coding-on-safex-links {
    margin-top: 5px;
    margin-bottom: 25px;
  }
  .choose-exchange-section a {
    margin-bottom: 20px;
  }
  .how-safex-works-hero .how-safex-works-hero-image{
    position: relative;
    overflow: hidden;
  }
  .how-safex-works-hero .how-safex-works-hero-image img{
    position: relative;
    width: 217%;
    max-width: unset;
    right: 91%;
  }
  .how-safex-works-hero .hero-text {
    padding-top: 0px;
  }

}

@media screen and (max-width: 576px) {
  .image-mb-full {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
}


@media screen and (min-width: 1000px) {
  #chartCollapse {
    display: block !important;
    height: 100% !important;
    overflow: unset !important;
  }
}

@media screen and (max-width: 1402px) {
  .card {
    width: 30%;
  }
  .card-wrap.card-2-col .card {
    width: 46%;
  }
  .btn-primary.btn {
    min-width: 150px;
  }
  .safex-info img {
    height: unset;
    margin-top: auto;
  }
  .safex-info .safex-info-image {
    height: 186px;
    display: flex;
  }
  .card-fluid-image img {
    position: relative !important;
    width: 400px !important;
    max-width: 100% !important;
    height: auto !important;
    margin-left: auto;
    margin-right: auto;
    right: 0px !important;
    margin-top: 0px !important;
  }
  .card-top {
    /*min-height: 166px;
    position: relative;*/
  }
  .eco-card-with-image-section .card-with-image-wrap .card-with-image .card-top {
  }
  .card-with-image-wrap .card-body {
    padding: 24px 28px;
  }
  .community-form-section .form-wrap form {
    width: 90%;
  }
  .card-with-image-wrap.four-rows {
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 24px;
  }

  .card-with-image-wrap.four-rows .card-with-image {
    min-width: 300px;
    margin-bottom: 30px;
  }

  /*.card-with-image-wrap.four-rows .card-top img {
    position: relative;
    bottom: 0px;
    width: 100%;
    height: auto;
    max-width: 100%;
  }*/
}

@media screen and (max-width: 1270px) {
  .additional-section .col-md-9 {
    width: 100%;
  }
  .claiming-section .col-md-9 {
    width: 100%;
  }
  .tablet-level-title {
    display: block;
  }
  .desktop-level-title {
    display: none;
  }
  .step-body-row {
    flex-direction: column;
  }
  .step-body .step-body-row .col-md-4 {
    width: 100%;
    max-width: 100%;
  }
  .step-body .step-body-row .col-md-6 {
    width: 100%;
    max-width: 100%;
  }
  .option-section .step-body-right {
    width: 100%;
  }

  .option-section .step-body h4 {
    margin-bottom: 10px !important;
    margin-top: 20px;
  }
  
  .option-section .step-body .step-body-row .col-md-4 {
    width: 100%;
  }

  .option-section .step-body .video {
    width: 82%;
  }
  .option-section .step-body h2 {
    margin-top: 20px;
  }
  .decide-section .decide-section-left {
    flex: 0 0 auto;
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
  
  .decide-section .decide-section-right {
    flex: 0 0 auto;
    width: 100%;
  }

  .philosophy-section .col-md-9 {
    width: 100%;
  }

  .knowledge-base-section h2 {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 5px !important;
    margin-top: 10px;
  }

  .knowledge-base-section h2 p {
    font-size: 16px;
    line-height: 26px;
  }

  .blockchain-section .col-md-9 {
    width: 100%;
  }
  nav form {
    max-width: 140px;
  }
  .coding-on-safex-section .hero-text {
    width: 100%;
    max-width: 100%;
  }
  .level-section .step .center {
    margin-left: 3%;
  }
  footer .footer-links a {
    font-size: 14px;
  }
}

@media screen and (max-width: 1000px) {
  .chart-wrap{
    flex-wrap: wrap;
    gap: 15px;
    margin: auto;
  }
}

@media screen and (max-width: 1180px) {
  .coding-on-safex-section .col-md-5 {
    width: 100% !important;
    order: 2 !important;
  }
  .coding-on-safex-section .col-md-6 {
    width: 100% !important;
    order: 1 !important;
  }
  .coding-on-safex-section .hero-image img {
    max-width: 100% !important;
    width: 425px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .vision-section .vision-col {
    width: 100%;
  }
  .vision-section .roadmap-col {
    width: 100%;
  }
  .vision-section .roadmap-col h2 {
    margin-bottom: 20px !important;
  }
  .opportunity-section {
    padding-top: 20px;
  }
  .opportunity-section .col-md-9 {
    width: 100%;
  }
  ul.road-map {
    margin-top: 10px;
  }
  .vision-section {
    padding-bottom: 0px;
  }
  .share-content-section .share-content-text-col {
    width: 100%;
  }
  
  .share-content-section .share-content-form-col {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    order: 2;
  }

  .share-content-section .col-md-5 {
    width: 100%;
  }

  .find-your-place-section {
    order: 1;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 23px;
    line-height: 37px;
    text-align: center;
  }

  .find-your-place-section .btn-arrow {
    margin: auto;
  }

  .find-your-place-section h1 {
    font-size: 23px;
    margin-bottom: 15px !important;
    line-height: 37px;
  }
}

@media screen and (max-width: 1167px) {
  .instructions-mobile-none {
    display: none;
  }
  .instructions-mobile-block {
    display: block;
    margin-bottom: 30px;
  }
  .what-need-section .col-md-10 {
    width: 100%;
  }
  .what-is-node-section .col-md-10 {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .price-card .tablet-text {
    display: block;
  }
  .coins-card-wrap-section .price-card .price-text {
    display: none;
  }
  .price-card .current-price {
    font-size: 26px;
  }
  .safex-coin-box-wrap .safex-coin-box-link-center {
    margin-left: 0px;
    margin-right: 0px;
  }


  .form-wrap h3 {
    font-weight: 500;
    font-size: 20px;
  }
  .form-wrap span {
    font-weight: 500;
    font-size: 20px;
  }
  
}

@media screen and (max-width: 900px) {

  .card-body .btn-arrow p {
    font-size: 19px;
    white-space: nowrap;
  }
  .card-body {
    padding: 20px 25px;
  }
  .card-with-image-wrap .card-body {
    padding: 20px 25px;
  }

  .navbar {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }

  .safex-elements {
    padding-top: 0px;
    margin-top: 0px;
  }

  .safex-elements .card-wrap {
    margin-top: 30px;
    gap: 15px;
  }

  .hero-2-cols .hero-text a {
    margin-top: 10px;
  }

  .blockchain-section {
    padding-top: 0px;
  }

  .blockchain-section .bg-gray {
    margin-top: 0px;
  }

  .privacy-necessary-section {
    margin-top: 10px;
  }

}
/*
@media screen and (max-width: 1000px) {
  .footer-links {
    padding-left: 10px;
    padding-right: 10px;
  }

  footer .footer-links a {
    font-size: 15px;
  }
}*/

@media screen and (max-width: 1000px) {
  .what-is-staking-section h1 {
    font-weight: 400 !important;
    font-size: 23px;
    margin-bottom: 14px;
  }
  .mission-hero {
    padding-bottom: 0px;
  }
  .level-section .step .center {
    margin-left: 10px;
  }
  .step .left, .step .right {
    flex: unset;
  }
  .step .step-number {
    padding: 20px;
  }
  .level-section .level-body a {
    margin-top: 40px;
  }
  .navbar .container {
    padding: 0px;
  }
  .navbar form {
    display: none !important;
  }
  .nav .dropdown-item {
    padding: 10px 20px;
    min-width: none;
  }
  .navbar-nav .nav-link {
    border-top: 0px;
    border-bottom: 1px solid #F0F0F0;
    padding: 10px 20px;
    font-weight: 600;
    font-size: 15px;
    line-height: 26px;
  }
  .navbar-nav .dropdown-menu {
    border-bottom: 1px solid #F0F0F0;
  }
  .dropdown-menu {
    border-radius: 0px;
    background-color: transparent;
    box-shadow: none;
  }
  nav .dropdown-item {
    font-weight: 400;
    font-size: 15px;
    line-height: 26px;
  }
  nav .dropdown-menu li:not(:first-child) {
    border: none;
  }
  .navbar-brand {
    padding-left: 20px;
  }
  .navbar-toggler {
    padding-right: 20px;
  }
  .row>* {
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    font-size: 22px;
  }
  .m-no-bg {
    background-color: transparent;
  }
  a.link-dark {
    color: #13D3FD;
  }
  .knowledge-base-section a.link-dark {
    color: #525252;
  }
  /*.knowledge-base-section ul {
    list-style: none;
  }
  .knowledge-base-section ul li::before {
    content: "\2022";
    color: #13D3FD;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }
  .knowledge-base-section ul li a {
    display: inline;
  }*/
  .hero-2-cols {
    padding: 40px 0px;
  }
  .bg-image-fluid {
    overflow: hidden;
  }
  .bg-image-fluid img{
    margin-left: -47%;
    max-width: unset;
    width: 193%;
  }
  .bg-image-fluid-m-nomral {
    width: 100%;
    max-width: 100%;
    margin-left: 0px;
  }
  .hero-image-fluid {
    padding: 0px;
  }
  .collapse-btn {
    display: flex;
    justify-content: space-between;
    gap: 18%;
  }
  .collapse-btn.chart-collapse-btn img {
    display: block;
  }
  .cart-collapse-bottom {
    display: block;
    text-align: center;
    cursor: unset !important;
    border: none !important;
    background-color: transparent !important;
  }
  .chart-wrap {
    flex-direction: column;
    margin-top: 30px;
  }
  .chart {
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
  }
  .card-wrap {
    flex-direction: column;
  }
  .card {
    width: 100%;
    margin-bottom: 20px;
  }
  .card-wrap.card-2-col .card {
    width: 100%;
  }
 
  
  .card-fluid.green-linear {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 67.16%, rgba(84, 234, 184, 0.4) 100%);
  }
  .card-fluid.blue-linear {
    background: linear-gradient(360deg, rgba(19, 211, 253, 0.3) 0.4%, rgba(255, 255, 255, 0.3) 34.71%);
  }
  .card-fluid.gray-linear {
    background: radial-gradient(612.27% 9.02% at 45% 96%, rgba(196, 196, 196, 0.8) 100%, rgba(255, 255, 255, 0) 231.89%);
}
  .card-fluid {
    flex-direction: column;
  }
  .card-fluid-body {
    width: 100% !important;
    padding: 30px;
  }
  .MiningSafexCashCryptocurrency .card-fluid-body {
    width: 100%;
  }
  .card-fluid-image {
    width: 100% !important;
    padding-right: 0px !important;
  }

  .card-fluid-image img {
    position: relative !important;
    width: 400px !important;
    max-width: 100% !important;
    margin-left: auto;
    margin-right: auto;
    right: 0px !important;
  }
  /*.MiningSafexCashCryptocurrency .card-fluid-image {
    padding: 0px 10px;
    width: 100%;
  }
  .MiningSafexCashCryptocurrency .card-fluid-image img {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .SellProductsonSafexMarket .card-top img {
    position: absolute;
    margin-top: 4%;
    height: 125%;
    width: auto;
    max-width: unset;
  }*/
  .card-fluid2 {
    flex-direction: column-reverse;
  }
  .card-fluid2-body {
    width: 100%;
    padding: 22px 30px;
  }
  .card-fluid2-image {
    width: 100%;
  }
  .card-fluid2-image img {
    max-width: 440px;
  }
  .card-fluid2-image img {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    transform: translate(-50%, -50%);
  }
  .ExploreSafex .card-fluid-image {
    padding: 0px 20px 20px;
  }
  .DiveIntoSafex .card-fluid-image {
    padding: 0px 20px 20px;
  }
  /*.card-top {
    min-height: 230px;
  }*/
  .card-fluid2-image {
    min-height: 210px;
  }


  .card-mobile {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 12px rgba(196, 196, 196, 0.3);
    border-radius: 10px;
    padding: 20px;
    margin: 30px 0px;
  }




  footer .footer-bottom {
    margin-top: 40px;
  }
  footer .social-media {
      justify-content: center;
      display: none;
  }
  footer .social-media-mobile {
    display: flex;
  }
  footer .footer-links-wrap {
      display: none;
  }
  footer .footer-bottom .footer-mobile-links {
      display: flex;
      justify-content: center;
      gap: 18px;
  }
  footer .footer-bottom img {
    margin-bottom: 40px;
  }

  .video {
    width: 100% !important;
    height: 280px !important;
  }

  .coins-card-wrap {
    align-items: center;
  }

  .step {
    text-align: left;
    gap: 16px;
  }

  .step .step-number p {
    font-weight: 500;
    font-size: 20px;
  }

  .step .step-number p span {
    font-size: 10px;
    line-height: 14px;
  }

  .step-body {
    text-align: left;
  }

  .step-body a {
    margin-left: 0px;
    margin-right: 0px;
  }

  .step-body ol {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 9px;
  }

  .safex-info-wrap {
    flex-direction: column;
  }

 
  
  .safex-info-wrap .right {
    justify-content: center;
  }

  .safex-info-wrap .center {
    flex-wrap: wrap;
  }

  .safex-info .safex-info-image {
    height: unset;
  }

  .safex-info {
    text-align: center;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

  .form-wrap form {
    width: 100%;
  }

  .form-wrap form h1 {
    font-weight: 500;
    font-size: 20px;
  }
  .form-wrap form h1 span {
    font-weight: 500;
    font-size: 20px;
  }

  .trademark-images img {
    height: 70px;
    width: auto;
    max-width: none;
  }

  .step-body .step-body-row {
    flex-direction: column;
  }

  .bg-gray-text {
    text-align: left;
  }

  .bg-gray-text h2 {
    margin-bottom: 10px;
  }
  
  .bg-gray-text a {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .bg-gray-text .text-center a {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .desktop-text-center,
  .desktop-text-center a {
    text-align: left;
    margin-left: 0px;
    margin-right: 0px;
  }

  .hero-text-fluid {
    text-align: left;
    margin-top: 50px;
  }
  
  .hero-text-fluid a {
    margin-left: 0px;
    margin-right: 0px;
  }

  .community-link-section h1 {
    font-weight: 400;
    font-size: 23px;
    margin-bottom: 16px;
    margin-top: 12px;
  }

  .community-link-section h2 {
    font-weight: 400;
    font-size: 23px;
  }

  .community-link-section p {
    font-weight: 400;
    font-size: 15px;
  }

  .eco-card-with-image-section .card-with-image-wrap .card-body {
    padding: 23px 25px;
  }
  .eco-card-with-image-section .card-body .btn-arrow p {
    font-size: 17px;
  }
  .buy-safex-image-section img {
    margin-left: 0px;
    width: 100%;
  }
  .philosophy-section {
    padding-top: 0px;
  }
  .foundation-hero-section {
    padding-bottom: 10px !important;
  }
  .vision-section {
    padding-top: 0px;
  }
  .card-with-image {
    min-width: 328px;
    margin-bottom: 30px;
  }
  .card-with-image-wrap {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 767px) {
  .coins-card-wrap {
    flex-direction: column;
  }
  .price-card .tablet-text {
    display: none;
  }
  .coins-card-wrap-section .price-card .price-text {
    display: block;
  }

  .qr-code-section .col-md-4 h4 {
    min-height: 40px;
}
}

@media screen and (max-width: 768px) {
  .safex-info-wrap-section {
    padding-bottom: 40px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .wallet-advice {
    padding: 30px 0px 3px 0px;
    margin-top: 10px;
  }
  .what-to-know-wallet {
    padding: 30px 0px 30px 0px;
  }
  .install-vallet-section {
    padding-bottom: 0px;
  }
  .install-vallet-section h1 {
    font-size: 20px;
    margin-bottom: 0px;
  }
  #chart-wrap-section .container-fluid {
    max-width: 540px;
  }
  .mobile-bg-gray {
    background: rgba(240, 240, 240, 0.5);
    margin: 40px 0px;
  }

  .wallet-advice h2 {
    font-size: 25px;
    text-align: center;
  }

  .wallet-advice ul {
    margin-bottom: 15px;
  }

  .wallet-advice .text-wrap a {
    margin-bottom: 27px;
    font-size: 16px;
  }

  .community-link-section {
    padding-bottom: 0px;
    padding-top: 0px;
  }
  .community-form-section img {
    margin-bottom: 40px;
  }
  .community-link-right {
    padding-left: 0px;
  }
  .privacy-necessary-section {
    margin-top: 0px;
  }
  .privacy-necessary-section h3 {
    line-height: 26px;
    font-size: 16px;
  }
  .blockchain-section a {
    font-size: 18px;
    line-height: 26px;
  }
  .btn-arrow {
    font-size: 18px;
    line-height: 26px;
  }
  .mining-pool-chart-section {
    background-color: white;
    margin: 0px;
  }
  .how-to-mine-cash-section * {
    font-weight: 400;
    font-size: 16px;
  }
  .how-to-mine-cash-section h1 {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
  }
  .foundation-section {
    padding-top: 0px;
  }
}

@media screen and (max-width: 600px) {
  /*.card-top {
    display: flex;
  }
  .card-top img {
    position: relative;
    display: block;
    margin-top: auto;
    transform: none !important;
    top: unset !important;
    bottom: 0px !important;
    height: 100%;
    width: auto !important;
    left: 0px !important;
    max-width: 100% !important;
    height: auto !important;
    width: 100% !important;
    margin-top: auto !important;
  }*/
  .card-with-image-section {
    padding-top: 40px;
  }
  .card-with-image-wrap {
    flex-direction: column;
    margin-bottom: 0px;
  }
  .card-with-image-wrap.four-rows {
    flex-wrap: wrap;
  }
  .card-with-image-wrap.space-around .card-with-image {
    width: 100%;
  }
  .card-with-image-wrap.four-rows .card-with-image {
    width: 100%;
  }
  .card-fluid-body .card-fluid-subtitle {
    font-size: 13px;
  }
  .card-with-image {
    width: 100%;
    margin-bottom: 100px;
  }
  .hero-image-fluid {
    overflow: hidden;
  }

  .hero-image-fluid img {
    margin-left: -47%;
    max-width: unset;
    width: 193%;
  }
  .card-with-image {
    margin-bottom: 30px;
  }
  
  .bg-gray-text h2 {
    font-size: 25px;
    margin-bottom: 33px !important;
  }

  .visual-content-section h2 {
    margin-bottom: 0px !important;
  }

  .home-gray-section h2 {
    font-weight: 400 !important;
    font-size: 25px;
    margin-bottom: 21px !important;
    line-height: 40px !important;
  }

  .home-gray-section p {
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 32px;
  }

  .home-gray-section a {
    font-size: 16px;
  }

  .video {
    height: 210px;
  }

  .safex-elements {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  .safex-elements .card-wrap {
    margin-top: 30px;
    gap: 9px;
  }

  .card-with-image-wrap-section {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .bg-image-fluid-section h1 {
    font-weight: 600;
    font-size: 25px;
  }


  .card-wrap-section {
    padding-top: 40px;
    padding-bottom: 25px;
  }

  .card-wrap-section .card-wrap {
    gap: 10px;
  }

  .card-wrap.card-2-col h3 {
    font-weight: 500;
    font-size: 18px;
  }

  .card-wrap.card-2-col p {
    font-weight: 400;
    font-size: 15px;
  }

  .card-wrap.card-2-col a {
    font-weight: 400;
    font-size: 15px;
  }

  .mb-no-border {
    border: none;
    box-shadow: none;
  }

  .hero-2-cols .hero-text h1 {
    font-weight: 500;
    font-size: 35px;
  }

  .hero-2-cols .hero-text h1 span{
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat';
  }

  .popover-btn-wrap {
    margin-top: 10px;
  }

  .hero-2-cols {
    padding-bottom: 0px;
  }

  .popover-section {
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 5px;
    padding-top: 27px;
  }

  .popover-section h1 {
    font-weight: 600;
    font-size: 25px;
  }

 

  .mb-no-m {
    margin-bottom: 0px !important;
  }

  .card-fluid2-body .card-fluid2-subtitle {
    margin-bottom: 0px;
  }

  .wallet-card-with-image-wrap {
    padding-top: 0px;
  }

  .buy-safex-cash-section .hero-image {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .SellProductsonSafexMarket {
    margin-top: 50px;
  }

  .card-fluid-image img {
    max-width: 92%;
  }

  .btn-primary.btn {
    min-width: 226px;
  }

  .card-fluid2-image img {
    max-width: 100%;
  }
  .price-card {
    width: 230px;
    max-width: 100%;
    padding: 0px;
    margin: 20px 0px;
  }
  .price-card .price-text {
    font-size: 12px;
  }
  .price-card .current-price {
    font-size: 20px;
    margin: 0px !important;
  }
  .price-card .price-action p {
    font-size: 12px;
  }
  .card-fluid-wrap-section {
    padding-top: 0px;
  }
  .coins-card-wrap-section {
    padding-top: 20px;
    padding-bottom: 20px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .keep-coins-safe-section {
    padding-top: 0px;
  }
  .have-questions-section {
    margin-top: 0px;
  }
  .have-questions-section h2 {
    margin-bottom: 0px !important;
    font-weight: 400 !important;
    font-size: 20px;
    text-align: center;
    line-height: 32px;
  }
  .safex-info br {
    display: none;
  }
  .safex-info-wrap .center {
    gap: 10px;
  }
  .safex-info p {
    margin-top: 14px;
  }
  .safex-info {
    width: 100%;
  }
  .safex-info img {
    height: 170px;
    margin: auto;
  }
  .eco-card-with-image-section .card-with-image-wrap .card-with-image {
    margin-bottom: 50px;
  }
  .step-section {
    padding-top: 20px;
  }
  .opportunity-section {
    padding-bottom: 0px;
  }
  .vision-section .roadmap-col {
    margin-top: 10px;
  }
  .vision-section .roadmap-col h2 {
    margin-left: 45px;
    margin-bottom: 20px !important;
  }
 
  
  .links-to-existing-section h1 {
    font-weight: 400;
    font-size: 23px;
  }
}

@media screen and (max-width: 576px) {
  .form-wrap {
    background: none;
    box-shadow: none;
    border-radius: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .form-wrap.form-wrap-no-p {
    padding: 0px;
  }
  .form-wrap form {
    background: #FFFFFF;
    box-shadow: 0px 0px 15px 12px rgb(196 196 196 / 30%);
    border-radius: 20px;
    padding: 50px 20px;
  }
  .mine-cash-image-section {
    margin-bottom: 40px;
  }
  .decide-section {
    padding-top: 0px;
  }
  .option-section .step-body h4 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px !important;
    margin-top: 20px;
    font-size: 16px;
  }
  .option-section .step-body p,
  .option-section .step-body a,
  .option-section .step-body * {
    font-weight: 400 !important;
    font-size: 16px !important;
  }
  .step {
    margin-bottom: 40px;
  }
  .step-section {
    padding-bottom: 20px;
  }
  

}